<template>
  <div class="c-credits-line">
    <div class="c-credits-line__credits">{{credits}} credits</div>
    <OLink :to="{name: 'credits'}" class="c-credits-line__buy" theme="primary">Buy credits</OLink>
  </div>
</template>

<script>
import OLink from '../objects/OLink'
import { mapGetters } from 'vuex'
export default {
  name: 'CCreditsInfoLine',
  components: { OLink },
  computed: {
    ...mapGetters({
      credits: 'getCredits'
    })
  }
}
</script>

<style lang="scss">
  .c-credits-line {
    display: flex;
    flex-flow: row nowrap;
  }

  .c-credits-line__buy {
    margin-left: auto;
  }
</style>
