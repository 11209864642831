<template>
  <o-button
    :is="btnType"
    :disabled="disabled || credits < value"
    :type="type"
    v-on="$listeners"
  >
    <slot></slot>
    <OCost :value="value" :division="division"></OCost>
  </o-button>
</template>

<script>
import OButton from '../objects/OButton'
import { mapGetters } from 'vuex'
import OCost from '../objects/OCost'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'

export default {
  name: 'CCreditButton',
  components: { OCost, OButton },
  props: {
    disabled: Boolean,
    division: String,
    type: String,
    value: Number
  },
  computed: {
    ...mapGetters({
      credits: 'getCredits'
    }),
    btnType () {
      return this.type === 'submit' ? OFormSubmitButton : OButton
    }
  }
}
</script>

<style scoped>

</style>
