<template>
  <span class="o-cost">{{value}} cr<template v-if="division">/{{division}}</template></span>
</template>

<script>
export default {
  name: 'OCost',
  props: {
    value: Number,
    division: String
  }
}
</script>

<style lang="scss">
  .o-cost {
    text-transform: lowercase;
    background: #333;
    color: #fff;
    border-radius: 0.6666em;
    box-sizing: border-box;
    display: inline-block;
    padding: 0.3333em;
    margin: -0.3333em 0.25em;
  }
</style>
